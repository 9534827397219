import { useSelector } from 'react-redux';
import { capitalize, isBoolean } from 'lodash';
import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { environment } from 'src/constants/surveyJson/environment';
import { selectPropertyQuestionnaireSlice } from 'src/redux/slices/propertyQuestionnaire';
import { QuestionnaireTypeEnum } from 'src/types';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { QuestionnairePropertyEnum } from 'src/utils/types';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { DescriptionBlock } from '../styles';
import { useQuestionnaire } from '../useQuestionnaire';

const QuestionnaireEnvironmentCard = () => {
	const { fullQuestionnaire } = useSelector(selectPropertyQuestionnaireSlice);
	const { onPageChanged, onSubmit, onComplete } = useQuestionnaire({
		type: QuestionnairePropertyEnum.environment,
		questionnaireType: QuestionnaireTypeEnum.PROPERTY,
	});

	const completedEnvironment = fillQuestionnaireJsonWithCompletedData(environment, fullQuestionnaire?.environment);

	return (
		<>
			<QuestionnarieModal
				questionnaireType={QuestionnaireTypeEnum.PROPERTY}
				initialCurrentPage={fullQuestionnaire?.environment.currentPage}
				questionnaireKey={QuestionnairePropertyEnum.environment}
				surveyJson={completedEnvironment}
				onPageChanged={onPageChanged}
				onSubmit={onSubmit}
				onComplete={onComplete}
				status={fullQuestionnaire?.environment.status}
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.PROPERTY}
				title='Environment'
				type={QuestionnairePropertyEnum.environment}
			>
				{({ details }) => (
					<>
						{isBoolean(details?.flooding?.flooded) && (
							<DescriptionBlock style={{ marginTop: '8px' }}>
								Flood: <b>{details.flooding.flooded ? 'Yes' : 'No'}</b>
							</DescriptionBlock>
						)}
						{isBoolean(details?.radon?.radon_test_safe) && (
							<DescriptionBlock style={{ marginTop: '8px' }}>
								Radon: <b>{details.radon.radon_test_safe ? 'Yes' : 'No'}</b>
							</DescriptionBlock>
						)}
						{details?.japanese_knotweed?.knotweed ? (
							<DescriptionBlock style={{ marginTop: '8px' }}>
								Knotweed: <b>{capitalize(details.japanese_knotweed.knotweed).replaceAll('_', ' ')}</b>
							</DescriptionBlock>
						) : null}
					</>
				)}
			</QuestionnaireCard>
		</>
	);
};

export default QuestionnaireEnvironmentCard;

import { call } from '../@';

export type Payload = {
	transactionId: string;
	title: { number: string; tenure: string; address: string };
};

export default async function selectTransactionTitle(payload: Payload) {
	return await call('transaction-selectTitle', payload);
}

import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { movingOut } from 'src/constants/surveyJson/movingOut';
import { selectTransactionQuestionnaireSlice } from 'src/redux/slices/transactionQuestionnaire';
import { useSelector } from 'src/redux/store';
import { QuestionnaireTypeEnum } from 'src/types';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { QuestionnaireTransactionEnum } from 'src/utils/types';
import { selectTransactionParticipants } from 'src/redux/slices/transaction';
import { selectProperty } from 'src/redux/slices/property';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { DescriptionBlock } from '../styles';
import { useQuestionnaire } from '../useQuestionnaire';

const QuestionnaireMovingOutCard = () => {
	const { fullQuestionnaire } = useSelector(selectTransactionQuestionnaireSlice);
	const participants = useSelector(selectTransactionParticipants);
	const property = useSelector(selectProperty);
	const { onSubmit, onPageChanged, onComplete } = useQuestionnaire({
		type: QuestionnaireTransactionEnum.moving_out,
		questionnaireType: QuestionnaireTypeEnum.TRANSACTION,
	});

	const completedTransactionMovingOut = fillQuestionnaireJsonWithCompletedData(
		movingOut(participants.participants, property),
		fullQuestionnaire?.moving_out,
	);

	return (
		<>
			<QuestionnarieModal
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				initialCurrentPage={fullQuestionnaire?.moving_out.currentPage}
				questionnaireKey={QuestionnaireTransactionEnum.moving_out}
				onSubmit={onSubmit}
				onComplete={onComplete}
				onPageChanged={onPageChanged}
				surveyJson={completedTransactionMovingOut}
				status={fullQuestionnaire?.moving_out.status}
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				title='Moving Out'
				type={QuestionnaireTransactionEnum.moving_out}
			>
				{({ details }) => {
					const areRequirementsVisible =
						details.timing_financing.moving_requirements_desc || details.timing_financing.moving_requirements;

					return (
						<>
							{/* {fullPropertyQuestionnaire?.property_details?.property_details?.tenure && (
								<DescriptionBlock>
									Occupancy: <b>{fullPropertyQuestionnaire?.property_details?.property_details.tenure}</b>
								</DescriptionBlock>
							)} */}
							<DescriptionBlock>
								{details.timing_financing?.seller_chainbound ? 'Chain-bound seller' : null}
								{details.timing_financing?.seller_chainbound === false ? 'Chain-free seller' : null}
							</DescriptionBlock>
							<DescriptionBlock>
								{details.occupiers?.vacant_possession ? 'Vacant possession' : null}
								{details.occupiers?.vacant_possession === false ? 'Not a vacant possession' : null}
							</DescriptionBlock>
							{areRequirementsVisible ? (
								<>
									<DescriptionBlock style={{ marginTop: '16px' }}>Requirements:</DescriptionBlock>
									<DescriptionBlock>
										<b>
											{details.timing_financing.moving_requirements
												? details.timing_financing.moving_requirements_desc
												: null}
											{details.timing_financing.moving_requirements === false ? 'None' : null}
										</b>
									</DescriptionBlock>
								</>
							) : null}
						</>
					);
				}}
			</QuestionnaireCard>
		</>
	);
};

export default QuestionnaireMovingOutCard;

import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getPropertyQuestionnaire, selectPropertyQuestionnaireSlice } from 'src/redux/slices/propertyQuestionnaire';
import {
	getTransactionQuestionnaire,
	selectTransactionQuestionnaireSlice,
} from 'src/redux/slices/transactionQuestionnaire';
import { dispatch } from 'src/redux/store';
import { useParams } from 'react-router';
import { selectPermissions } from 'src/redux/slices/permissions';
import { Box } from '@mui/material';
import Progress from 'src/components/Progress';
import QuestionnairePropertyCard from 'src/components/cards/QuestionnairePropertyDetailsCard/QuestionnairePropertyCard';
import QuestionnaireOwnershipCard from 'src/components/cards/QuestionnaireOwnershipCard/QuestionnaireOwnershipCard';
import QuestionnaireServicesCard from 'src/components/cards/QuestionnaireServicesCard/QuestionnaireServicesCard';
import QuestionnaireEnvironmentCard from 'src/components/cards/QuestionnaireEnvironmentCard/QuestionnaireEnvironmentCard';
import QuestionnaireBuildingWorksCard from 'src/components/cards/QuestionnaireBuildingWorksCard/QuestionnaireBuildingWorksCard';
import QuestionnaireRightsCard from 'src/components/cards/QuestionnaireRightsCard/QuestionnaireRightsCard';
import QuestionnaireGuaranteeCard from 'src/components/cards/QuestionnaireGuaranteeCard/QuestionnaireGuaranteeCard';
import SellerFinanceCard from 'src/components/cards/SellerFinanceCard';
import QuestionnaireBuyerCard from 'src/components/cards/QuestionnaireBuyerCard/QuestionnaireBuyerCard';
import { QuestionnaireLiabilitiesCard } from 'src/components/cards/QuestionnaireLiabilitiesCard/QuestionnaireLiabilitiesCard';
import QuestionnaireMovingOutCard from 'src/components/cards/QuestionnaireMovingOutCard/QuestionnaireMovingOutCard';
import { GiftorFinanceCard } from 'src/components/cards/GiftorFinanceCard/GiftorFinanceCard';
import { QuestionnaireSellContent } from 'src/components/cards/QuestionnaireSellContent/QuestionnaireSellContent';
import QuestionnaireMovingInCard from 'src/components/cards/QuestionnaireMovingInCard/QuestionnaireMovingInCard';
import { selectUser } from 'src/redux/slices/auth';
import { QuestionnaireStatus, VerificationSessionStatus } from 'src/types';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { selectTransactionOverview, selectTransactionParticipants } from 'src/redux/slices/transaction';
import ProprietorFinanceCard from 'src/components/cards/ProprietorFinanceCard';
import { QuestionnaireFittingsAndContents } from 'src/components/cards/QuestionnaireFittingsAndContentsCard/QuestionnaireFittingsAndContents';
import Category from 'src/pages/transactions/participants/components/Category';
import { TransactionQuestionnairesSection, TransactionQuestionnairesWrapper } from './styles';
import { IPropertyQuestionnaires } from '../property/types';

const TransactionQuestions: FC<IPropertyQuestionnaires> = ({ propertyId }) => {
	const { id = '' } = useParams();
	const participants = useSelector(selectTransactionParticipants);
	const permissions = useSelector(selectPermissions);
	const user = useSelector(selectUser);
	const transaction = useSelector(selectTransactionOverview);
	const { loading: isPropertyQuestionnaireLoading } = useSelector(selectPropertyQuestionnaireSlice);
	const { loading: isTransactionQuestionnaireLoading, fullQuestionnaire: transactionQuestionnaire } = useSelector(
		selectTransactionQuestionnaireSlice,
	);

	const personalDetails = transactionQuestionnaire?.personal_details?.[user.uid];

	useEffect(() => {
		dispatch(getTransactionQuestionnaire({ id }));
		if (propertyId) dispatch(getPropertyQuestionnaire({ id: propertyId }));
	}, [id]);

	useEffect(() => {
		if (
			user.verificationStatus !== VerificationSessionStatus.VERIFIED &&
			user.isPrincipal &&
			!transaction.isBuyerViewer
		) {
			dispatch(openModal({ name: modals.completeIdChecks }));

			return;
		}

		if (personalDetails && personalDetails.personal?.status !== QuestionnaireStatus.DONE) {
			dispatch(openModal({ name: modals.completePersonalDetails }));
		}
	}, [user.verificationStatus]);

	if (isPropertyQuestionnaireLoading || isTransactionQuestionnaireLoading || !permissions.questionnaire.isInitialized) {
		return (
			<Box height='100%' width='100%' display='flex' alignItems='center' justifyContent='center'>
				<Progress />
			</Box>
		);
	}

	return (
		<TransactionQuestionnairesWrapper>
			{transaction.isInstrument ? (
				<>
					<Category title='Property'>
						<TransactionQuestionnairesSection>
							<QuestionnaireOwnershipCard />
							<QuestionnaireGuaranteeCard />
						</TransactionQuestionnairesSection>
					</Category>
					<Category title='Borrower'>
						<TransactionQuestionnairesSection>
							<ProprietorFinanceCard />
						</TransactionQuestionnairesSection>
					</Category>
				</>
			) : (
				<>
					<Category title='Property'>
						<TransactionQuestionnairesSection>
							<QuestionnairePropertyCard />
							<QuestionnaireOwnershipCard />
							<QuestionnaireServicesCard />
							<QuestionnaireEnvironmentCard />
							<QuestionnaireBuildingWorksCard />
							<QuestionnaireRightsCard />
							<QuestionnaireGuaranteeCard />
							<QuestionnaireLiabilitiesCard />
						</TransactionQuestionnairesSection>
					</Category>
					<Category title='Sell-Side'>
						<TransactionQuestionnairesSection>
							<SellerFinanceCard />
							<QuestionnaireMovingOutCard />
							<QuestionnaireFittingsAndContents />
							{transactionQuestionnaire?.fitting_contents?.items_for_sale?.items_for_sale_yn && (
								<QuestionnaireSellContent />
							)}
						</TransactionQuestionnairesSection>
					</Category>
					<Category title='Buy-Side'>
						<TransactionQuestionnairesSection>
							<QuestionnaireBuyerCard />
							<QuestionnaireMovingInCard />
							{participants.participants.buyers
								.filter((b) => b.relationship === 'giftor')
								.map((g) => (
									<GiftorFinanceCard key={g.uid} participant={g} />
								))}
						</TransactionQuestionnairesSection>
					</Category>
				</>
			)}
		</TransactionQuestionnairesWrapper>
	);
};

export default TransactionQuestions;

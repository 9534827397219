import { transactionOnboardingSteps } from 'src/pages/transactions/constants';
import { noop } from 'lodash';
import { dispatch } from 'src/redux/store';
import {
	selectTransactionOnboarding,
	selectTransactionTitle,
	updateTransactionOnboarding,
} from 'src/redux/slices/transaction';
import { OnboardingStepStatusEnum } from 'src/types';
import useLoading from 'src/hooks/useLoading';
import { useSnackbar } from 'notistack';
import Progress from 'src/components/Progress';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { labelByPropertyTitleTenure } from 'src/constants/title';
import { ConfirmationModal } from '../../common';

const TransactionTitleConfirmationModal = () => {
	const { isLoading, setIsLoading } = useLoading();
	const onboarding = useSelector(selectTransactionOnboarding);
	const { enqueueSnackbar } = useSnackbar();

	const handleConfirm = async () => {
		if (!onboarding?.selectedTitle) return;

		try {
			setIsLoading(true);

			await dispatch(selectTransactionTitle({ title: onboarding.selectedTitle })).unwrap();
			await dispatch(updateTransactionOnboarding({ titleConfirmation: OnboardingStepStatusEnum.PASSED }));

			enqueueSnackbar('Transaction title successfully selected!');
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	const handleBack = async () => {
		setIsLoading(true);

		await dispatch(
			updateTransactionOnboarding({
				title: OnboardingStepStatusEnum.PENDING,
				titleHelp: OnboardingStepStatusEnum.PENDING,
			}),
		);

		setIsLoading(false);
	};

	return (
		<ConfirmationModal
			modalName={transactionOnboardingSteps.titleConfirmation}
			title='CONFIRM TITLE'
			withButtons={false}
			handleClose={noop}
			isLoading={isLoading}
			sx={{ maxWidth: '550px' }}
			description={
				onboarding?.selectedTitle ? (
					<>
						You have selected{' '}
						<b>
							{onboarding.selectedTitle.number} ({labelByPropertyTitleTenure[onboarding.selectedTitle.tenure]})
						</b>
						. If you made a mistake, don't worry! A member of our team can help you change your selection later.
						<Box display='flex' gap='15px' justifyContent='flex-end' mt='15px'>
							<LoadingButton onClick={handleBack} variant='outlined' size='large' loading={isLoading}>
								Back
							</LoadingButton>
							<LoadingButton variant='contained' size='large' loading={isLoading} onClick={handleConfirm}>
								Confirm
							</LoadingButton>
						</Box>
					</>
				) : (
					<Box py='30px'>
						<Progress zoom={0.8} />
					</Box>
				)
			}
		/>
	);
};

export default TransactionTitleConfirmationModal;

import { FC } from 'react';
import { Stack } from '@mui/material';
import { isEmptyChildren } from 'src/utils/common';
import { CategoryProps } from './types';
import { ParticipantsCategory, ParticipantsCategoryList, ParticipantsCategoryTitle } from '../../style';

const Category: FC<CategoryProps> = ({ title, children, isVisible = true, withHeader = true }) => {
	if (!children || !isVisible) return null;

	if (isEmptyChildren(children)) return null;

	return (
		<ParticipantsCategory>
			{withHeader && (
				<Stack gap='5.5px'>
					<ParticipantsCategoryTitle>{title}</ParticipantsCategoryTitle>
					<hr color='#F5F8FF' />
				</Stack>
			)}
			<ParticipantsCategoryList>{children}</ParticipantsCategoryList>
		</ParticipantsCategory>
	);
};

export default Category;

import { MouseEvent, ReactNode } from 'react';

export const stopPropagation = (e: MouseEvent) => e.stopPropagation();

export const isEmptyChildren = (children: ReactNode) => {
	if (Array.isArray(children) && !children.filter(Boolean).flat().length) return true;

	return !children;
};

export const descendingSortingComparator = (a: string | number | null, b: string | number | null) => {
	if ((a === null && b === null) || (a === undefined && b === undefined)) {
		return 0;
	}
	if (a === null || a === undefined) {
		return 1;
	}
	if (b === null || b === undefined) {
		return -1;
	}

	if (b < a) {
		return -1;
	}
	if (b > a) {
		return 1;
	}

	return 0;
};

export const getSortingComparator = <T>(order: string, getter: (value: T) => string | number | null) =>
	order === 'desc'
		? (a: T, b: T) => descendingSortingComparator(getter(a), getter(b))
		: (a: T, b: T) => -descendingSortingComparator(getter(a), getter(b));

export const isValidDate = (value: Date | string | number) => {
	if (value instanceof Date && !Number.isNaN(value.getTime())) return true;
	if (typeof value === 'string') return !Number.isNaN(Date.parse(value));
	if (typeof value === 'number') return !Number.isNaN(new Date(value).getTime());

	return false;
};

export const wrapOptionalValue = <T, R>(value: T | undefined | null, wrapper: (s: T) => R) => {
	if (!value) return null;

	return wrapper(value);
};

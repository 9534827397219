/* eslint-disable no-return-await */
import { getAuth } from 'firebase/auth';
import type MethodArgsResultMap from './api-methods';
import HTTPMethod from './http-method.enum';

type MaybePromise<T> = T | PromiseLike<T>;

const functionsLink = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;

const noBodyMethods: HTTPMethod[] = [HTTPMethod.get, HTTPMethod.head, HTTPMethod.options];

const methodsMap: {
	[method in keyof MethodArgsResultMap]: [
		httpMethod: HTTPMethod,
		requiresAuth: boolean,
		customSerializer?: (data: MethodArgsResultMap[method][0]) => MaybePromise<{
			body: ReadableStream | Blob | BufferSource | string | undefined;
			headers: { [x: string]: string };
		}>,
		customDeserializer?: (res: Response) => MaybePromise<MethodArgsResultMap[method][1]>,
	];
} = {
	'transactionOnboarding-sync': [HTTPMethod.post, true],
	'transactionOnboarding-notification': [HTTPMethod.post, true],

	'propertyOnboarding-notification': [HTTPMethod.post, true],
	'propertyOnboarding-sync': [HTTPMethod.post, true],
	'propertyOnboarding-cancel': [HTTPMethod.post, true],

	'property-add': [HTTPMethod.post, true],
	'property-checkExists': [HTTPMethod.post, true],
	'property-checkAlreadyOnSale': [HTTPMethod.post, true],
	'property-confirmTitleSelection': [HTTPMethod.post, true],

	'transactionInvitation-accept': [HTTPMethod.post, false],
	'transactionInvitation-reject': [HTTPMethod.post, true],
	'transactionInvitation-resend': [HTTPMethod.post, true],
	'transactionInvitation-invite': [HTTPMethod.post, true],

	'epc-findAddressByPostcode': [HTTPMethod.post, true],

	'yoti-issueNotification': [HTTPMethod.post, true],
	'yoti-verifyUser': [HTTPMethod.post, true],
	'yoti-verificationSession': [HTTPMethod.get, true],

	'permissions-getQuestionnaire': [HTTPMethod.get, true],

	'sessions-start': [HTTPMethod.post, true],
	'sessions-resume': [HTTPMethod.post, true],
	'sessions-end': [HTTPMethod.post, true],

	'hmlr-searchPropertyTitles': [HTTPMethod.post, true],
	'hmlr-getPropertyTitles': [HTTPMethod.post, true],
	'hmlr-getPropertyAvailableDocuments': [HTTPMethod.post, true],
	'hmlr-downloadPropertyDocuments': [HTTPMethod.post, true],

	'profilePicture-remove': [HTTPMethod.delete, true],
	'profilePicture-upload': [
		HTTPMethod.put,
		true,
		({ file, type }) => ({ body: file, headers: { 'X-Mime-Type': type } }),
	],

	'user-getList': [HTTPMethod.get, true],
	'user-changeEmailRequest': [HTTPMethod.post, true],
	'user-verifyChangeEmailRequest': [HTTPMethod.post, true],
	'user-approveChangeEmailRequest': [HTTPMethod.post, true],
	'user-rejectChangeEmailRequest': [HTTPMethod.post, true],
	'user-confirmChangeEmailRequest': [HTTPMethod.post, false],
	'user-cancelChangeEmailRequest': [HTTPMethod.post, true],
	'user-resendChangeEmailRequestVerification': [HTTPMethod.post, true],
	'user-updateQuestionnaires': [HTTPMethod.post, true],

	'auth-registration': [HTTPMethod.post, false],
	'auth-validateEmail': [HTTPMethod.post, false],
	'auth-checkUserNotExist': [HTTPMethod.post, false],
	'auth-resetPassword': [HTTPMethod.post, false],
	'auth-confirmResetPassword': [HTTPMethod.post, false],
	'auth-confirmation': [HTTPMethod.post, true],
	'auth-resendVerificationCode': [HTTPMethod.post, true],

	'invitation-getUsers': [HTTPMethod.post, true],
	'invitation-getMetadata': [HTTPMethod.get, true],

	'transaction-getTrail': [HTTPMethod.post, true],
	'transaction-getTasks': [HTTPMethod.post, true],
	'transaction-add': [HTTPMethod.post, true],
	'transaction-scanQrCode': [HTTPMethod.post, true],
	'transaction-generateQrCode': [HTTPMethod.post, true],
	'transaction-abandon': [HTTPMethod.post, true],
	'transaction-generateDocument': [HTTPMethod.post, true],
	'transaction-getOverview': [HTTPMethod.post, true],
	'transaction-getParticipants': [HTTPMethod.post, true],
	'transaction-getSummary': [HTTPMethod.post, true],
	'transaction-getSummaries': [HTTPMethod.get, true],
	'transaction-addPlaceholder': [HTTPMethod.post, true],
	'transaction-updatePlaceholder': [HTTPMethod.post, true],
	'transaction-removePlaceholder': [HTTPMethod.post, true],
	'transaction-selectTitle': [HTTPMethod.post, true],
	'property-askTitleSelectionHelp': [HTTPMethod.post, true],
	'transaction-sync': [HTTPMethod.post, true],
	'transaction-uploadPlaceholderVerificationDocument': [
		HTTPMethod.post,
		true,
		({ file, type, transactionId, placeholderId }) => ({
			body: file,
			headers: { 'Content-Type': type, 'X-Transaction-Id': transactionId, 'X-Placeholder-Id': placeholderId },
		}),
	],

	'payment-request': [HTTPMethod.post, true],

	'documents-get': [HTTPMethod.get, true],
	'documents-updateFolder': [HTTPMethod.post, true],
	'documents-deleteFolder': [HTTPMethod.post, true],
	'documents-addFolder': [HTTPMethod.post, true],
	'documents-delete': [HTTPMethod.delete, true],
	'documents-upload': [
		HTTPMethod.post,
		true,
		({ file, location, type, folderId, propertyId, name }) => ({
			body: file,
			headers: {
				'Content-Type': type,
				'X-Folder-Id': folderId,
				'X-Location': location,
				'X-Property': propertyId,
				'X-Name': name,
			},
		}),
	],

	// legacy
	invitation: [HTTPMethod.post, true],
	acceptInvitation: [HTTPMethod.post, true],
	rejectInvitation: [HTTPMethod.post, true],
	revokeInvitation: [HTTPMethod.post, true],
	resendInvitation: [HTTPMethod.post, true],
};

export default async function call<M extends keyof MethodArgsResultMap>(
	method: M,
	args: MethodArgsResultMap[M][0],
	opts: Partial<RequestInit> = {},
): Promise<MethodArgsResultMap[M][1]> {
	const url = new URL(`${functionsLink}/${method}`);
	const [httpMethod, requiresAuth, customSerializer, customDeserializer] = methodsMap[method];
	const headers: { [x: string]: string } = {};
	let body;
	if (requiresAuth) {
		const { currentUser } = getAuth();
		if (!currentUser) throw new Error(`Method ${method} requires user to be authenticated first`);
		headers.Authorization = `Bearer ${await currentUser.getIdToken()}`;
	}
	if (noBodyMethods.includes(httpMethod)) {
		Object.keys(args).forEach((argName) => {
			if (args[argName] === undefined || args[argName] === null) return;
			url.searchParams.append(argName, String(args[argName]));
		});
	} else if (customSerializer) {
		const serialized = await customSerializer(args);
		body = serialized.body;
		Object.keys(serialized.headers).forEach((header) => {
			headers[header] = serialized.headers[header];
		});
	} else {
		body = JSON.stringify(args);
		headers['Content-Type'] = 'application/json';
	}
	const fetchOptions: RequestInit & { duplex?: string } = {
		method: httpMethod,
		body,
		headers,
		...opts,
	};
	if (body && body instanceof ReadableStream) {
		fetchOptions.duplex = 'half';
	}
	const res = await fetch(url, fetchOptions);
	if (customDeserializer) return await customDeserializer(res);
	if (res.ok) return await res.json();
	const e = new Error(await res.text()) as Error & { code: number };
	e.code = res.status;
	throw e;
}

export const ERROR_USER_NOT_FOUND = 'auth/user-not-found';
export const ERROR_WRONG_PASSWORD = 'auth/wrong-password';
export const ERROR_EMAIL_IN_USE = 'auth/email-already-in-use';

export const MESSAGE_USER_NOT_FOUND = 'Incorrect email or password';
export const MESSAGE_WRONG_PASSWORD = 'Wrong password';
export const MESSAGE_EMAIL_IN_USE = 'A user with that email already exists';
export const MESSAGE_INCORRECT_PASS = 'Incorrect password. Please try a different one.';
export const MESSAGE_INACTIVE_USER = 'This user is not active. Please check your inbox for the activation email.';

export const ERROR_MESSAGES_BY_CODE = {
	[ERROR_USER_NOT_FOUND]: MESSAGE_USER_NOT_FOUND,
	[ERROR_WRONG_PASSWORD]: MESSAGE_WRONG_PASSWORD,
	[ERROR_EMAIL_IN_USE]: MESSAGE_EMAIL_IN_USE,
};

export const RESPONSE_CODES = {
	INVITATION: {
		USER_EXISTS_UNDER_OTHER_ROLE: 'USER_EXISTS_UNDER_OTHER_ROLE',
	},
	DOCUMENTS: {
		DOWNLOADING_ACKNOWLEDGEMENT: 'DOCUMENT_DOWNLOADING_ACKNOWLEDGEMENT',
	},
};

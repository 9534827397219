// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
// redux
import * as Sentry from '@sentry/react';
import { store, persistor } from './redux/store';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

//
import App from './App';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------

if (process.env.REACT_APP_ENV_MODE !== 'local') {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const isIframe = window.self !== window.top;

if (isIframe) {
	root.render(<div>Forbidden</div>);
} else {
	root.render(
		<HelmetProvider>
			<ReduxProvider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<SettingsProvider>
							<CollapseDrawerProvider>
								<BrowserRouter>
									<App />
								</BrowserRouter>
							</CollapseDrawerProvider>
						</SettingsProvider>
					</LocalizationProvider>
				</PersistGate>
			</ReduxProvider>
		</HelmetProvider>,
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { FormProvider, useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { ActionButtons } from 'src/layouts/main/styles';
import { groupPropertyTitles, handleCloseModal } from 'src/utils/propertyHelpers';
import { RHFRadioGroup } from 'src/components/hook-form';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectTransactionSummary } from 'src/redux/slices/transaction';
import { LoadingButton } from '@mui/lab';
import { getPropertyTitles, selectProperty, selectPropertyTitles } from 'src/redux/slices/property';
import { dispatch } from 'src/redux/store';
import Progress from 'src/components/Progress';
import { BrandModal } from 'src/components/common';
import { openModal } from 'src/redux/slices/modal';
import { AssignTitleForm } from './types';
import { validationSchema } from './validation';

const Content = () => {
	const property = useSelector(selectProperty);
	const { data: summary } = useSelector(selectTransactionSummary);
	const { data: titles, isInitialized } = useSelector(selectPropertyTitles);
	const form = useForm<AssignTitleForm>({ resolver: yupResolver(validationSchema) });

	useEffect(() => {
		dispatch(getPropertyTitles({ id: property.id }));
	}, [property.id]);

	useEffect(() => {
		if (!summary?.general.titleId) return;

		form.reset({ title: summary.general.titleId });
	}, [summary?.general.titleId]);

	const handleSubmit = async (data: AssignTitleForm) => {
		const selectedTitle = titles.find((t) => t.number === data.title);

		if (!selectedTitle) return;

		dispatch(openModal({ name: modals.titleAssignmentConfirmation, payload: selectedTitle }));
	};

	const handleSearch = () => dispatch(openModal({ name: modals.searchTitle }));

	const groupedTitles = groupPropertyTitles(titles);

	return (
		<FormProvider {...form}>
			<form
				onSubmit={(e) => {
					e.stopPropagation();
					form.handleSubmit(handleSubmit)(e);
				}}
			>
				<BrandModal.Title title='ASSIGN TITLE' />
				<Box py='15px'>
					{!isInitialized ? (
						<Box pt='30px' pb='50px'>
							<Progress />
						</Box>
					) : (
						<div>
							<Box display='flex' flexDirection='column' gap='10px'>
								{groupedTitles.length ? (
									groupedTitles.map((group) => (
										<Box key={group.address}>
											<Typography fontWeight={600} fontSize='15px'>
												{group.address}
											</Typography>
											<RHFRadioGroup name='title' row={false} sx={{ width: '100%' }} options={group.titles} />
										</Box>
									))
								) : (
									<Typography>
										We couldn't find any titles for this address.{' '}
										<Typography
											sx={{ textDecoration: 'underline', cursor: 'pointer' }}
											display='inline'
											color='primary'
											onClick={handleSearch}
										>
											Search title manually
										</Typography>
									</Typography>
								)}
							</Box>
							<ActionButtons mt='20px'>
								<LoadingButton variant='outlined' size='large' fullWidth onClick={handleCloseModal}>
									Cancel
								</LoadingButton>
								<LoadingButton variant='contained' size='large' fullWidth type='submit' disabled={!titles.length}>
									Select
								</LoadingButton>
							</ActionButtons>
						</div>
					)}
				</Box>
			</form>
		</FormProvider>
	);
};

const AssignTitleModal = () => (
	<Modal name={modals.assignTitle} cardSx={{ overflow: 'visible', maxWidth: '600px' }}>
		<Content />
	</Modal>
);

export default AssignTitleModal;
